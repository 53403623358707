.intro {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.intro h1 {
  line-height: 1;
}

.know span {
  margin: 0 0.2rem;
}
.tex {
  line-height: 72px;
}
.profile {
  position: absolute;
  right: 0;
  width: 465px;
  z-index: -1;
}
.text1{
  transform: translate(-30px, 0px);
}
.tex span{
  font-family: 'Playfair Display', sans-serif;
}
.text2{
  transform: translate(-80px, 0px);
}
.text3{
  transform: translate(20px, 0px);
}