.row {
  display: flex;
}
.col {
  padding: 0 1rem;
}

.canvas > div {
  z-index: 1;
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}