/* new */
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.section {
  position: relative;
  min-height: 100vh;
}
.section-gray {
  background: gray;
}
.section-lightblue {
  background: lightblue;
}
.horizontal-sections {
  position: relative;
  overflow: hidden;
}
.pin-wrap,
.animation-wrap {
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
}

.horizontal-sections1 {
  position: relative;
  overflow: hidden;
}
.pin-wrap1,
.animation-wrap1 {
  display: flex;
  position: relative;
  z-index: 1;
  height: 100vh;
}

.select {
  font-weight: 500;
}
.select_work {
  font-family: "Playfair Display", sans-serif;
}
