.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #b82e1f 74%);
}

.bar {
  position: fixed;
  width: 100vw;
  height: 105vh;

  z-index: 50000;
}

.counter {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: white;
  z-index: 10001;
  padding: 0.2em 14px;
  font-size: 90px;
}
